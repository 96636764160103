@import "../../Styles/GlobalStyles.scss";

.lightGray {
  background-color: #f8f8f8;
}

.rowelement {
  background-color: $pp-light-gray;
}

.noPadding {
  padding-left: 0px !important;
}

.linkStyle {
  color: inherit;
  text-decoration: inherit;
}

.activetag {
  background-color: #17315a;
  min-width: 0.5rem;
}

.disabled {
  color: var(--bs-gray-500);
}

.nodisplay {
  display: none;
}
