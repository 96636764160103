/* Required Imports */
@import "../../node_modules/bootstrap/scss/functions";
@import "../../node_modules/bootstrap/scss/variables";
@import "../../node_modules/bootstrap/scss/variables-dark";
@import "../../node_modules/bootstrap/scss/mixins";

/*=========================================================
  Global Colors
  ⚠ - Before adding custom colors please verify that they are NOT
      already declared in bootstrap
 ========================================================*/
$pp-secondary: #96358d;
$pp-primary: #17315a;
$pp-light-gray: #e0e0e0;
$pp-navbar-gray: #f8f8f8;

/*=========================================================
  Custom Buttons
    > Using bootstrap mixins to maintain styling.
    > See: https://getbootstrap.com/docs/5.2/components/buttons/#sass-mixins
 ========================================================*/
.btn-pp-secondary {
  @include button-variant($pp-secondary, $pp-secondary);
}

.btn-pp-secondary-outline {
  @include button-outline-variant($pp-secondary);
}

.btn-pp-landing {
  @include button-variant($blue, $blue);
}

// Login component Sign-In/Sign-up button styling
.btn-auth-nav {
  @include button-variant(
                  $white,
                  $gray-200,
                  $gray-600,
                  $white,
                  #E9ECEF,
                  $black,
                  $white,
                  #0D6EFD,
                  $black
  );
  border-style: solid;
  border-width: 0 0 3px 0; /* 25px top, 10px right, 4px bottom and 35px left */
  border-radius: 0;
  font-weight: 500;
}

//  View Password button for Auth flows
.btn-view-password {
  @include button-variant(
                  $gray-100,
                  #CFD4D9,
                  $black
  )
}

/*=========================================================
  Custom Styles for protected page Headers and subtext
 ========================================================*/

.text-pp-secondary {
  color: $pp-secondary;
}

#introHR {
  margin-right: 170px;
}

.bg-navbar-gray {
  background-color: $pp-navbar-gray;
}

/*=========================================================
  Navbar customization
 ========================================================*/

.navbar-brand > svg {
  height: 1.5rem;
}
@include media-breakpoint-up(sm) {
  .navbar-brand > svg {
    height: 2.375rem;
  }
}

/*=========================================================
  HTML For overrides/styling
 ========================================================*/

// Remove up/down arrows on number input boxes chrome, safari, edge, opera
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}


// Firefox
input[type=number] {
  -moz-appearance: textfield;
}
