@import "./GlobalStyles.scss";
.minPageHeight {
  min-height: 100vh;
}

.grayBackground {
  background-color: $pp-navbar-gray;
}

.maxWidth {
  max-width: 800px;
}
