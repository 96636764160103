@import "./GlobalStyles.scss";
#introCard {
  color: #212529;
}

#introHR {
  margin-right: 170px;
}

a, #WDownload {
  text-decoration: none;
}

#introTitle {
  color: $pp-secondary;
}

.indicate-text{
  font-size: 14px;
}

.blueCardBox {
  background: #D3E0FC;
  color: wheat;
  padding: 16px;
  border-radius: 4px;
}

.dropzone {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: #707070;
  border-style: dashed;
  background-color: #fafafa;
  outline: none;
  transition: border .24s ease-in-out;
  position: relative;
}
.btn-upload-button{
  display: block !important;
  opacity: 0;
  position: absolute;
  top: 75px;
}